import kiwiton from "./kiwiton-logo.jpeg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={kiwiton} className="logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
